<template>
  <v-dialog
    v-model="isImportProcurementsModalVisible"
    width="600"
    max-width="60%"
    persistent
  >
    <v-card>
      <v-card-title>{{ $t("procurements.import") }}</v-card-title>
      <v-card-text>
        <v-file-input
          v-model="file"
          accept=".xlsx"
          label="Select Excel file"
        ></v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          @click="toggleImportProcurementsModal"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          text
          @click="confirmImport"
          :disabled="!valid"
        >
          {{ $t("import") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          text
          @click="getImportTemplate()"
          :loading="is_loading_csv"
        >
          {{ $t("download_excel_template") }}
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              text
              @click="showInstructionsDialog"
              v-on="on"
            >
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("hubs.info") }}</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="isInfoDialogVisible">
      <v-card>
        <v-card-title>{{ $t("hubs.info") }}</v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">{{ $t("hub_id") }}</th>
                  <th class="text-left">{{ $t("hub_name") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(hub, index) in hubs"
                  :key="index"
                >
                  <td>{{ hub.id }}</td>
                  <td>{{ hub.name }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="isInfoDialogVisible = false"
            >{{ $t("close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isConfirmationDialogVisible">
      <v-card>
        <v-card-title>{{ $t("procurements.confirm") }}</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="fileContent"
            item-key="product_id"
          >
            <template v-slot:item.hubs="{ item }">
              <span
                v-for="(hub, index) in item.hubs"
                :key="index"
                >{{ getHubNamebyId(hub) }}<br
              /></span>
            </template>
            <template v-slot:item.start_date="{ item }">
              {{ excelToDate(item.start_date) }}
            </template>
            <template v-slot:item.end_date="{ item }">
              {{ excelToDate(item.end_date) }}
            </template>
           
          </v-data-table>
          <v-alert
            v-if="importStatus === 'failed'"
            text
            prominent
            type="error"
            icon="mdi-cloud-alert"
          >
            <v-row align="center">
              <v-col class="grow">
                {{ $t("import_products_error") }},<br />
              </v-col>
              <v-col class="shrink">
                <v-btn
                  color="primary"
                  text
                  @click="getImportFailedResult()"
                  :loading="is_loading_csv"
                  >{{ $t("download") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="cancelImport"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            text
            @click="sendImport"
            >{{ $t("confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import * as XLSX from "xlsx";
import slugify from "slugify";
import moment from "moment";
export default {
  name: "ImportProcurementsModal",
  data: () => ({
    file: null,
    isConfirmationDialogVisible: false,
    isInfoDialogVisible: false,
    is_loading_csv: false,
    fileContent: null,
    headers: [

      { text: "Product Id", value: "product_id" },
      { text: "Product Name", value: "product_name" },
      { text: "Hub Name", value: "hub_name" },
      { text: "Supplier Name", value: "supplier_name" },
      { text: "Delivery Type", value: "delivery_type" },
      { text: "Unit", value: "unit" },
      { text: "Quantity", value: "quantity" },
      { text: "Product Bar Code", value: "product_bar_code" },
      { text: "Discount %", value: "discount_percent" },
      { text: "Shelf", value: "shelf" },
      { text: "Category", value: "category" },
      { text: "Sub Category", value: "sub_category" },
    ],

  }),
  computed: {
    ...mapState("supplyOrders", [
      "isImportProcurementsModalVisible",
      "importErrorFile",
      "importStatus",
    ]),
    valid() {
      return this.file !== null;
    },
    ...mapGetters({
      isLoadingHubs: "hubs/isLoadingHubs",
      hubs: "hubs/listHubs",
    }),
  },
  methods: {
    ...mapActions("supplyOrders", {
      toggleImportProcurementsModal: "toggleImportProcurementsModal",
      importProcurements: "importProcurements",
    }),
    ...mapMutations("supplyOrders", ["SET_IMPORT_PROCUREMENTS_MODAL_VISIBLE"]),
    showInstructionsDialog() {
      this.isInfoDialogVisible = true;
    },
    async readExcelFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
          const slugifiedHeaders = headers.map((header) =>
            slugify(header, { lower: true, replacement: "_" })
          );
          const range = XLSX.utils.decode_range(worksheet["!ref"]);
          range.s.r += 1; // Exclude header row
          const json = XLSX.utils.sheet_to_json(worksheet, {
            header: slugifiedHeaders,
            range,
          });
          resolve(json);
        };
        reader.onerror = (e) => {
          reject(e);
        };
        reader.readAsArrayBuffer(file);
      });
    },
    async confirmImport() {
      try {
        const excelData = await this.readExcelFile(this.file);
        this.fileContent = excelData.reduce((acc, obj) => {
          const {
                product_id,
                product_name,
                hub_name,
                supplier_name,
                delivery_type,
                unit,
                quantity,
                product_bar_code,
                discount_percent,
                shelf,
                category,
                sub_category,
          } = obj;
            acc.push({
              product_id,
              product_name,
              hub_name,
              supplier_name,
              delivery_type,
              unit,
              quantity,
              product_bar_code,
              discount_percent,
              shelf,
              category,
              sub_category,
            
            });
          // }
          return acc;
        }, []);
        this.isConfirmationDialogVisible = true;
      } catch (error) {
        console.error(error);
      }
    },
    cancelImport() {
      this.isConfirmationDialogVisible = false;
      this.file = null;
      this.fileContent = [];
      this.$store.commit("supplyOrders/SET_IMPORT_STATUS", null);
    },
    async sendImport() {
      try {
        this.is_uploading = true;
        await this.$store.dispatch("supplyOrders/import", this.file);
        this.isConfirmationDialogVisible = false;
        this.SET_IMPORT_PROCUREMENTS_MODAL_VISIBLE(false);
        this.file = null;
        this.$swal("", this.$t("procurements.import_success"), "success");
      } catch (error) {
        if (error?.response?.data?.file) {
          this.$store.commit("supplyOrders/SET_IMPORT_STATUS", "failed");
          this.$store.commit(
            "supplyOrders/SET_IMPORT_ERROR_FILE",
            error.response.data.file
          );
        } else {
          console.error(error);
          this.$swal("", this.$t("procurements.import_failed"), "error");
        }
      }
    },
    async getImportTemplate() {

      this.is_loading_csv = true;
      await this.$store
        .dispatch("supplyOrders/downloadTemplate")
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "procurements_import.xlsx");
          document.body.appendChild(link);
          link.click();
          this.is_loading_csv = false;
        })
        .catch((error) => {
          this.is_loading_csv = false;
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        });
    },
    async getImportFailedResult() {
      this.is_loading_csv = true;
      await this.$store
        .dispatch("supplyOrders/downloadFailedResult")
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "procurements_import.xlsx");
          document.body.appendChild(link);
          link.click();
          this.is_loading_csv = false;
        })
        .catch((error) => {
          this.is_loading_csv = false;
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        });
    },
    getHubNamebyId(id) {
      const hub = this.hubs.find((hub) => hub.id === id);
      return hub ? hub.name : id + this.$t("hub_not_found");
    },
    excelToDate(excelDate) {
      const INVALID_DATE_MESSAGE = this.$t("invalid_date");
      if (typeof excelDate !== "number") {
        return INVALID_DATE_MESSAGE;
      }
      const date = moment((excelDate - 25569) * 86400 * 1000);
      if (!date.isValid()) {
        return INVALID_DATE_MESSAGE;
      }
      return date.format("DD/MM/YYYY");
    },
  },
  created() {
    this.$store.dispatch("hubs/list");
  },
};
</script>